import React, { useEffect, useState } from "react";
import logoImage from "../assets/adc_home_white.png";
import { useHistory } from "react-router-dom";
import { register } from "../api/AccountApi";
import Button from "@mui/material/Button";
import {
  TextField,
  Box,
  CssBaseline,
  Container,
  Avatar,
  Snackbar,
  Alert,
} from "@mui/material";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { ApiError } from "../api/ApiRequest";
import BackIcon from "../assets/icon_back.svg";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../components/LoadingIndicator";
import { useAppDispatch } from "../app/hooks";
import { registerAsync } from "../app/AccountSlice";

export const RegisterPage = () => {
  const navigate = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setPasswordMatch(password == confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    setFormValid(
      email.length > 0 &&
        password.length > 8 &&
        confirmPassword.length > 8 &&
        passwordMatch,
    );
  }, [email, password, confirmPassword, passwordMatch]);

  useEffect(() => {
    setInvalidPassword(password.length < 8);
  }, [password]);

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
    setInvalidEmail(false);
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleRegister = async () => {
    if (!emailIsValid(email)) {
      setInvalidEmail(true);
      return;
    }

    if (!passwordIsValid(password)) {
      setInvalidPassword(true);
      return;
    }

    if (password !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }
    try {
      setIsBusy(true);
      await dispatch(registerAsync({ email, password })).unwrap();

      navigate.push("/home");
    } catch (error: any) {
      console.error(error);
      if (error.error == "malformed_request") {
        setErrorMessage(t("validation_emailInvalid"));
      } else if (error.error == "password_not_strong_enough") {
        setErrorMessage(t("dialog_alert_passwordTooWeak"));
      } else if (error.error == "email_already_in_use") {
        setErrorMessage(t("dialog_alert_emailAlreadyInUse"));
      }
      setSnackbarOpen(true);
    } finally {
      setIsBusy(false);
    }
  };

  const emailIsValid = (email: string) => email.length > 0;
  const passwordIsValid = (password: string) => password.length >= 8;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className="form-background">
        <div className="form">
          <div className="back-button" onClick={() => navigate.goBack()}>
            <img src={BackIcon} alt={t("button_back")} className="back-icon" />
            {t("button_back")}
          </div>
          <LogoImage />
          {isBusy === true && <LoadingIndicator />}
          <TextField
            variant="outlined"
            label={t("label_email")}
            className="form-input"
            value={email}
            onChange={handleEmailChange}
            type="text"
            color={invalidEmail ? "error" : "primary"}
            name="name"
            margin="normal"
          />
          {invalidEmail && (
            <div className="invalid-alert">
              {t("validation_passwordLength")}
            </div>
          )}
          <TextField
            variant="outlined"
            label={t("label_password")}
            className="form-input"
            value={password}
            onChange={handlePasswordChange}
            color={invalidPassword ? "error" : "primary"}
            type="password"
            name="name"
            margin="normal"
          />

          {invalidPassword && (
            <div className="invalid-alert">
              {t("validation_passwordLength")}
            </div>
          )}

          <TextField
            variant="outlined"
            label={t("label_confirmPassword")}
            className="form-input"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            color={invalidPassword ? "error" : "primary"}
            type="password"
            name="name"
            margin="normal"
          />

          {!passwordMatch && (
            <div className="invalid-alert">
              {t("validation_passwordsNoMatch")}
            </div>
          )}

          <Button
            className="form-button"
            type="submit"
            variant="contained"
            size="large"
            startIcon={<KeyOutlinedIcon />}
            onClick={handleRegister}
            disabled={!formValid}
          >
            {t("button_createAccount")}
          </Button>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={errorMessage}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={handleSnackbarClose}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};

function LogoImage() {
  return <img src={logoImage} className="form-logo" alt="adc-logo"></img>;
}
