import React from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom"; // Import Link
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { HomePage } from "./pages/HomePage";
import { GroupPage } from "./pages/GroupPage";
import { MenuPage } from "./pages/MenuPage";
import { ScenarioPage } from "./pages/ScenarioPage";
import "./App.css";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { DeviceInfoPage } from "./pages/DeviceInfoPage";
import { DeviceSettingsPage } from "./pages/DeviceSettingsPage";
import { DeviceFavouritePage } from "./pages/DeviceFavouritePage";
import { DeviceVentilationPage } from "./pages/DeviceVentilationPage";
import { ForgottenPasswordPage } from "./pages/ForgottenPasswordPage";
import { ChangePasswordPage } from "./pages/ChangePasswordPage";
import { AddPasswordPage } from "./pages/AddPasswordPage";
import { DoorDeviceInfoPage } from "./pages/DoorDeviceInfoPage";
import { DoorSettingsPage } from "./pages/DoorSettingsPage";
import { DeleteAccountPage } from "./pages/DeleteAccountPage";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { GoogleOAuthProvider } from "@react-oauth/google";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#7bbbcd",
    },
    secondary: {
      main: "#95e7ff",
    },
    background: {
      default: "#121212",
      paper: "#1E1E1E",
    },
    text: {
      primary: "#ffffff",
      secondary: "rgba(255,255,255,0.6)",
      disabled: "rgba(45,44,44,0.59)",
    },
    divider: "#4b6972",
    error: {
      main: "#f73628",
      contrastText: "#ffffff",
    },
    info: {
      main: "#ffffff",
    },
  },
});

const Routes: React.FC = () => {
  return (
    <Provider store={store}>
      <Switch>
        <Route path="/register">
          <RegisterPage />
        </Route>
        <Route path="/forgottenpassword">
          <ForgottenPasswordPage />
        </Route>
        <Route path="/resetpassword">
          <ResetPasswordPage />
        </Route>
        <Route path="/home" component={MenuPage} />
        <Route path="/groups" component={GroupPage} />
        <Route path="/scenarios">
          <ScenarioPage />
        </Route>
        <Route path="/menu">
          <MenuPage />
        </Route>
        <Route path="/changepassword">
          <ChangePasswordPage />
        </Route>
        <Route path="/deleteaccount">
          <DeleteAccountPage />
        </Route>
        <Route path="/addpassword">
          <AddPasswordPage />
        </Route>
        <Route path="/devicepage">
          <DeviceInfoPage />
        </Route>
        <Route path="/doordeviceinfopage">
          <DoorDeviceInfoPage />
        </Route>
        <Route path="/devicesettingspage">
          <DeviceSettingsPage />
        </Route>
        <Route path="/devicefavouritepage">
          <DeviceFavouritePage />
        </Route>
        <Route path="/deviceventilationpage">
          <DeviceVentilationPage />
        </Route>
        <Route path="/doorsettingspage">
          <DoorSettingsPage />
        </Route>
        <Route exact path="/">
          <LoginPage />
        </Route>
      </Switch>
    </Provider>
  );
};

const queryClient = new QueryClient();

const googleClientId = (
  window as Window & typeof globalThis & { config: { googleClientId: string } }
).config.googleClientId;

export const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
