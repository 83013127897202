import { apiRequest } from "./ApiRequest";

export async function getDeviceList(): Promise<Record<string, string>> {
  return await apiRequest<Record<string, string>>(
    {
      method: "GET",
      endpoint: "/api/v1/device/list",
      query: { index: "token" },
    },
    { usesApiToken: true },
  );
}

export async function deleteDevice(
  token: string,
  force: boolean = false,
): Promise<void> {
  return await apiRequest(
    {
      method: "DELETE",
      endpoint: "/api/v1/device/delete",
      query: { devicetoken: token, force },
    },
    { usesApiToken: true },
  );
}
