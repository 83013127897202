import React, { useEffect, useRef, useState } from "react";

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const AwningCanvas = () => {
  return (
    <>
      <Canvas style={{ width: "100%", height: "100%" }} />
    </>
  );
};
const Canvas: React.FC<CanvasProps> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [rectPosition, setRectPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const onresize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const parent = canvas.parentElement;
      if (!parent) return;
      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(parent.clientWidth * scale);
      canvas.height = Math.floor(parent.clientHeight * scale);
    };
    window.addEventListener("resize", onresize);
    onresize();
    return window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const canvasRect = canvas.getBoundingClientRect();
        setRectPosition({ x: 0, y: e.clientY - canvasRect.top });
      }
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousemove", handleMouseMove);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragging]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.scale(scale, scale);

    const cWidth = canvas.width / scale;
    const cHeight = canvas.height / scale;

    //awning
    const gradientRectWidth = cWidth * 0.05;
    const gradientRectHeight = cHeight - 20;
    const totalRectangles = cWidth / gradientRectWidth;
    const curveHeight = 40;

    function drawGradientRectangle(
      ctx: CanvasRenderingContext2D,
      startX: number,
      gradientRectWidth: number,
    ) {
      const gradient = ctx.createLinearGradient(
        startX,
        0,
        startX + gradientRectWidth,
        0,
      );
      gradient.addColorStop(0.28, "rgb(236, 236, 236)");
      gradient.addColorStop(0.7, "rgb(234, 234, 234)");
      gradient.addColorStop(0.89, "rgb(227, 227, 227)");
      gradient.addColorStop(1.0, "rgb(217, 217, 217)");

      ctx.fillStyle = gradient;
      ctx.fillRect(startX, 0, gradientRectWidth, gradientRectHeight);
    }

    function drawCurvedGradient(
      ctx: CanvasRenderingContext2D,
      startX: number,
      gradientRectWidth: number,
      gradientRectHeight: number,
      curveHeight: number,
    ) {
      const gradient = ctx.createLinearGradient(
        startX + gradientRectWidth,
        0,
        startX,
        0,
      );
      ctx.beginPath();
      ctx.moveTo(startX, gradientRectHeight);
      ctx.quadraticCurveTo(
        startX + gradientRectWidth / 2,
        gradientRectHeight + curveHeight,
        startX + gradientRectWidth,
        gradientRectHeight,
      );
      gradient.addColorStop(0.28, "rgb(236, 236, 236)");
      gradient.addColorStop(0.7, "rgb(234, 234, 234)");
      gradient.addColorStop(0.89, "rgb(227, 227, 227)");
      gradient.addColorStop(1.0, "rgb(217, 217, 217)");
      ctx.fillStyle = gradient;
      ctx.fill();
    }
    ctx.save();
    ctx.translate(0, rectPosition.y - cHeight);

    for (let i = 0; i < totalRectangles; i++) {
      const startX = i * gradientRectWidth;
      drawGradientRectangle(ctx, startX, gradientRectWidth);
      drawCurvedGradient(
        ctx,
        startX,
        gradientRectWidth,
        gradientRectHeight,
        curveHeight,
      );
    }
    ctx.restore();

    // TODO: make it into a diamond with percentage of how open/closed the blinds are
    const percentage = (rectPosition.y / cHeight) * 100;

    // Diamant
    const centerX = cWidth / 2;
    const centerY = cHeight / 2;
    const radius = 70;
    const sides = 5;

    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate(-Math.PI / 2); // Rotate the canvas to align the pentagon vertically

    ctx.beginPath();
    ctx.moveTo(radius * Math.cos(0), radius * Math.sin(0));

    for (let i = 1; i <= sides; i++) {
      const angle = (i * (2 * Math.PI)) / sides;
      ctx.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
    }
    ctx.closePath();
    ctx.fillStyle = "rgba(45, 45, 45, 1)";
    ctx.fill();

    ctx.restore();

    //  Progress bar
    const progressRadius = radius;
    //convert the percentage to an angle in radians,
    //considering a full circle is 2 * Math.PI radians.
    const progressAngle = (percentage / 100) * (2 * Math.PI);

    ctx.lineWidth = 3;
    ctx.strokeStyle = "rgba(149, 239, 247, 1)";

    ctx.beginPath();
    ctx.arc(
      centerX,
      centerY,
      progressRadius,
      -Math.PI / 2,
      -Math.PI / 2 + progressAngle,
      false,
    );
    ctx.stroke();

    // Percentage text
    ctx.fillStyle = "white";
    ctx.font = "30px Nunito";
    const text = `${percentage.toFixed(0)}%`;
    const textWidth = ctx.measureText(text).width;
    ctx.fillText(text, centerX - textWidth / 2, centerY + 10);
    ctx.restore();
  }, [rectPosition]);

  return (
    <canvas
      {...props}
      style={{ width: "100%", height: "100%" }}
      ref={canvasRef}
    />
  );
};
