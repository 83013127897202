import {
  configureStore,
  createListenerMiddleware,
  isAnyOf,
} from "@reduxjs/toolkit";
import {
  AccountSlice,
  loginAsync,
  loginFederatedAsync,
  setupAsync,
} from "./AccountSlice";
import {
  localStorageAccountKey,
  localStorageMiddleware,
} from "./localStorageMiddleware";

const initialAccountState = JSON.parse(
  localStorage.getItem(localStorageAccountKey) || "null",
);
console.log(`Initial account state: `, initialAccountState);

export const store = configureStore({
  preloadedState: {
    account: initialAccountState === null ? undefined : initialAccountState,
  },
  reducer: {
    account: AccountSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(localStorageMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
