import React, { useEffect, useRef, useState } from "react";

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const ScreenCanvas = () => {
  return (
    <>
      <Canvas style={{ width: "100%", height: "100%" }} />
    </>
  );
};
const Canvas: React.FC<CanvasProps> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [rectPosition, setRectPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const onresize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const parent = canvas.parentElement;
      if (!parent) return;

      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(parent.clientWidth * scale);
      canvas.height = Math.floor(parent.clientHeight * scale);
    };
    window.addEventListener("resize", onresize);
    onresize();
    return window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const canvasRect = canvas.getBoundingClientRect();
        setRectPosition({ x: 0, y: e.clientY - canvasRect.y });
      }
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousemove", handleMouseMove);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragging]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.scale(scale, scale);

    const cWidth = canvas.width / scale;
    const cHeight = canvas.height / scale;

    //screen
    const gradient = ctx.createLinearGradient(0, 0, 0, cHeight);
    gradient.addColorStop(0, "rgba(255, 255, 255, 1)");
    gradient.addColorStop(0.13, "rgba(255, 255, 255, 1)");
    gradient.addColorStop(0.24, "rgba(247, 247, 247, 1)");
    gradient.addColorStop(0.4, "rgba(226, 226, 226, 1)");
    gradient.addColorStop(0.6, "rgba(193, 193, 193, 1)");
    gradient.addColorStop(0.81, "rgba(147, 147, 146, 1)");
    gradient.addColorStop(1.0, "rgba(102, 102, 102, 1)");
    ctx.save();
    ctx.translate(0, rectPosition.y - cHeight);
    ctx.fillStyle = gradient;
    ctx.save();

    ctx.fillRect(0, 0, cWidth, cHeight);
    const rectangleHeight = 3;

    ctx.fillStyle = "white";
    ctx.fillRect(0, cHeight - rectangleHeight, cWidth, rectangleHeight);
    ctx.restore();

    ctx.restore();
    // TODO: make it into a diamond with percentage of how open/closed the blinds are
    const percentage = (rectPosition.y / cHeight) * 100;

    // Diamant
    const centerX = cWidth / 2;
    const centerY = cHeight / 2;
    const radius = 70;
    const sides = 5;

    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate(-Math.PI / 2);
    ctx.beginPath();
    ctx.moveTo(radius * Math.cos(0), radius * Math.sin(0));

    for (let i = 1; i <= sides; i++) {
      const angle = (i * (2 * Math.PI)) / sides;
      ctx.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
    }
    ctx.closePath();
    ctx.fillStyle = "rgba(45, 45, 45, 1)";
    ctx.fill();

    ctx.restore();

    //  Progress bar
    const progressRadius = radius;
    //convert the percentage to an angle in radians,
    //considering a full circle is 2 * Math.PI radians.
    const progressAngle = (percentage / 100) * (2 * Math.PI);

    ctx.lineWidth = 3;
    ctx.strokeStyle = "rgba(149, 239, 247, 1)";

    ctx.beginPath();
    ctx.arc(
      centerX,
      centerY,
      progressRadius,
      -Math.PI / 2,
      -Math.PI / 2 + progressAngle,
      false,
    );
    ctx.stroke();

    // Percentage text
    ctx.fillStyle = "white";
    ctx.font = "30px Nunito";
    const text = `${percentage.toFixed(0)}%`;
    const textWidth = ctx.measureText(text).width;
    ctx.fillText(text, centerX - textWidth / 2, centerY + 10);
    ctx.restore();
  }, [rectPosition]);

  return (
    <canvas
      {...props}
      style={{ width: "100%", height: "100%" }}
      ref={canvasRef}
    />
  );
};
