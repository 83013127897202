import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { deleteDevice, getDeviceList } from "../api/DeviceApi";

export type Device = {
  id: string;
  name: string;
};

export interface DeviceSliceData {
  devices: Device[] | null;
}

export const forceDeleteAllDevices = createAsyncThunk(
  "device/deleteAll",
  async (_, thunkAPI) => {
    try {
      let deviceList = await getDeviceList();

      for (let deviceId in deviceList) {
        try {
          await deleteDevice(deviceId, true);
        } catch (err) {
          console.warn(`Failed to delete device: ${err}`);
        }
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const DeviceSlice = createSlice<
  DeviceSliceData,
  SliceCaseReducers<DeviceSliceData>,
  string,
  SliceSelectors<DeviceSliceData>,
  string
>({
  name: "device",
  initialState: {
    devices: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(forceDeleteAllDevices.fulfilled, (state, action) => {
      state.devices = [];
    });
  },
});
