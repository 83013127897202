import React, { useEffect, useRef, useState } from "react";

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export const RollerShutterCanvas = () => {
  return (
    <>
      <Canvas style={{ width: "100%", height: "100%" }} />
    </>
  );
};
const Canvas: React.FC<CanvasProps> = ({ ...props }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [rectPosition, setRectPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const onresize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const parent = canvas.parentElement;
      if (!parent) return;

      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(parent.clientWidth * scale);
      canvas.height = Math.floor(parent.clientHeight * scale);
    };
    window.addEventListener("resize", onresize);
    onresize();
    return window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const canvasRect = canvas.getBoundingClientRect();
        setRectPosition({ x: 0, y: e.clientY - canvasRect.y });
      }
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousemove", handleMouseMove);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragging]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    const scale = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.scale(scale, scale);

    const cWidth = canvas.width / scale;
    const cHeight = canvas.height / scale;

    // Create roller shutter canvas
    const gradientRectWidth = cWidth;
    const gradientRectHeight = cHeight * 0.05; // 54 -> velikost 1 lamele
    const gradientRectHeight2 = cHeight * 0.011; // 11 -> velikost prostora med lamelam
    const totalSlateHeight = gradientRectHeight + gradientRectHeight2; // 65 -> velikost obeh skupaj

    const position = Math.min(Math.max(rectPosition.y / cHeight, 0.0), 1.0);
    const percentage = position * 100;

    const botPos =
      cHeight * Math.min(Math.max(position / 0.95, 0.0), 1.0) -
      totalSlateHeight;
    let slateDrawCount = Math.ceil(botPos / totalSlateHeight) + 1;
    let squishedSpace = 0;

    function drawSlate1(
      ctx: CanvasRenderingContext2D,
      gradientRectHeight: number,
    ) {
      const gradient = ctx.createLinearGradient(0, 0, 0, gradientRectHeight);
      gradient.addColorStop(0, "rgba(255, 255, 255, 255)");
      gradient.addColorStop(1, "rgba(114, 114, 114, 255)");

      ctx.fillStyle = gradient;
      ctx.fillRect(
        0,
        gradientRectHeight2, //namesto 0 daš gradientRectHeight2
        gradientRectWidth,
        gradientRectHeight,
      );
    }

    function drawSlate2(
      ctx: CanvasRenderingContext2D,
      gradientRectHeight2: number,
      canvasWidth: number,
    ) {
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");

      ctx.fillStyle = "rgba(222, 223, 222, 255)";
      ctx.fillRect(0, 0, gradientRectWidth, gradientRectHeight2);
      if (!tempCtx) {
        return;
      }

      tempCanvas.width = canvasWidth;
      tempCanvas.height = gradientRectHeight2;

      // Draw ellipses
      const ellipseWidth = 4;
      const ellipseHeight = 1.5;
      const numberOfEllipses = Math.floor(canvasWidth / (ellipseWidth * 2));
      const ellipseSpacing = 20;
      const offset = 5;

      tempCtx.fillStyle = "rgba(0, 0, 0, 1)";
      for (let i = 0; i < numberOfEllipses; i++) {
        const ellipseX = i * ellipseSpacing + offset;
        const ellipseY = gradientRectHeight2 / 2;
        tempCtx.beginPath();
        tempCtx.ellipse(
          ellipseX,
          ellipseY,
          ellipseWidth,
          ellipseHeight,
          0,
          0,
          2 * Math.PI,
        );
        tempCtx.fill();
      }

      // Cut out the ellipses
      ctx.globalCompositeOperation = "destination-out";
      ctx.drawImage(tempCanvas, 0, 0);
      ctx.globalCompositeOperation = "source-over";
    }

    if (position - 0.95 > 0) {
      const maxVentilationSpace =
        (cHeight / gradientRectHeight) * gradientRectHeight2;
      const ventSpace = ((position - 0.95) / 0.05) * maxVentilationSpace;
      squishedSpace = ventSpace;

      slateDrawCount += Math.ceil(squishedSpace / gradientRectHeight);
    }
    ctx.save();
    ctx.translate(0, botPos);

    for (let i = 0; i < slateDrawCount; i++) {
      const canvasWidth = cWidth;
      if (squishedSpace < gradientRectHeight2) {
        drawSlate2(ctx, gradientRectHeight2, canvasWidth);
      }
      drawSlate1(ctx, gradientRectHeight); //odstrani gradientRectHeight - gradientRectHeight2 in nadomesti samo z gradientRectHeight
      if (squishedSpace == 0) {
        ctx.translate(0, -totalSlateHeight);
      } else {
        if (squishedSpace >= gradientRectHeight2) {
          ctx.translate(0, -gradientRectHeight);
          squishedSpace -= gradientRectHeight2;
        } else {
          ctx.translate(0, -(totalSlateHeight - squishedSpace));
          squishedSpace = 0;
        }
      }
    }
    ctx.restore();

    // TODO: make it into a diamond with percentage of how open/closed the blinds are

    // Diamant
    const centerX = cWidth / 2;
    const centerY = cHeight / 2;
    const radius = 70;
    const sides = 5;

    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate(-Math.PI / 2);
    ctx.beginPath();
    ctx.moveTo(radius * Math.cos(0), radius * Math.sin(0));

    for (let i = 1; i <= sides; i++) {
      const angle = (i * (2 * Math.PI)) / sides;
      ctx.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
    }
    ctx.closePath();
    ctx.fillStyle = "rgba(45, 45, 45, 1)";
    ctx.fill();

    ctx.restore();

    //  Progress bar
    const progressRadius = radius;
    //convert the percentage to an angle in radians,
    //considering a full circle is 2 * Math.PI radians.
    const progressAngle = (percentage / 100) * (2 * Math.PI);

    ctx.lineWidth = 3;
    ctx.strokeStyle = "rgba(149, 239, 247, 1)";

    ctx.beginPath();
    ctx.arc(
      centerX,
      centerY,
      progressRadius,
      -Math.PI / 2,
      -Math.PI / 2 + progressAngle,
      false,
    );
    ctx.stroke();

    // Percentage text
    ctx.fillStyle = "white";
    ctx.font = "30px Nunito";
    const text = `${percentage.toFixed(0)}%`;
    const textWidth = ctx.measureText(text).width;
    ctx.fillText(text, centerX - textWidth / 2, centerY + 10);
    ctx.restore();
  }, [rectPosition]);

  return (
    <canvas
      {...props}
      style={{ width: "100%", height: "100%" }}
      ref={canvasRef}
    />
  );
};
