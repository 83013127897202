import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import * as AccountApi from "../api/AccountApi";
import { forceDeleteAllDevices } from "./DeviceSlice";
import { googleLogout } from "@react-oauth/google";

export interface AccountSliceData {
  profile: AccountApi.UserProfile | null;
  isLoggedIn: boolean;
  isConfirmed: boolean;
  isPasswordSet: boolean;
}

export const loginAsync = createAsyncThunk(
  "account/login",
  async ({ email, pass }: { email: string; pass: string }, thunkAPI) => {
    try {
      const apiToken = await AccountApi.login(email, pass);

      await thunkAPI.dispatch(setupAsync()).unwrap();

      return { email, apiToken };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const loginFederatedAsync = createAsyncThunk(
  "account/loginFederated",
  async ({ idToken }: { idToken: string }, thunkAPI) => {
    try {
      const apiToken = await AccountApi.federatedLogin(idToken);

      thunkAPI.dispatch(setupAsync()).unwrap();

      return { apiToken };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const logoutAsync = createAsyncThunk(
  "account/logout",
  async (_, thunkAPI) => {
    try {
      await AccountApi.logout();

      googleLogout();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const logoutEverywhereAsync = createAsyncThunk(
  "account/logout_everywhere",
  async (_, thunkAPI) => {
    try {
      await AccountApi.logoutEverywhere();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const setupAsync = createAsyncThunk(
  "account/setup",
  async (_p, thunkAPI) => {
    try {
      const profile = await AccountApi.getUserProfile();

      return { profile };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteAccountAsync = createAsyncThunk(
  "account/delete",
  async ({ state, token }: { state: string; token: string }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(forceDeleteAllDevices());

      await AccountApi.deleteAccount(state, token);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const addPasswordAsync = createAsyncThunk(
  "account/addpassword",
  async (
    { request, password }: { request: string; password: string },
    thunkAPI,
  ) => {
    try {
      await AccountApi.addPassword(request, password);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const registerAsync = createAsyncThunk(
  "account/register",
  async (
    { email, password }: { email: string; password: string },
    thunkAPI,
  ) => {
    try {
      await AccountApi.register(email, password, "link");

      await thunkAPI.dispatch(loginAsync({ email, pass: password }));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const AccountSlice = createSlice<
  AccountSliceData,
  SliceCaseReducers<AccountSliceData>,
  string,
  SliceSelectors<AccountSliceData>,
  string
>({
  name: "account",
  initialState: {
    profile: null,
    isLoggedIn: false,
    isConfirmed: false,
    isPasswordSet: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.isLoggedIn = true;
    });
    builder.addCase(loginFederatedAsync.fulfilled, (state, action) => {
      state.isLoggedIn = true;
    });
    builder.addCase(logoutAsync.fulfilled, (state, action) => {
      state.profile = null;
      state.isLoggedIn = false;
    });
    builder.addCase(logoutEverywhereAsync.fulfilled, (state, action) => {
      state.profile = null;
      state.isLoggedIn = false;
      state.isConfirmed = false;
      state.isPasswordSet = false;
    });
    builder.addCase(setupAsync.fulfilled, (state, action) => {
      state.profile = action.payload.profile;
      state.isConfirmed = action.payload.profile.confirmed;
      state.isPasswordSet = action.payload.profile.password_set;
    });
    builder.addCase(deleteAccountAsync.fulfilled, (state, action) => {
      state.profile = null;
      state.isLoggedIn = false;
      state.isConfirmed = false;
      state.isPasswordSet = false;
    });
    builder.addCase(addPasswordAsync.fulfilled, (state, action) => {
      state.isPasswordSet = true;
    });
  },
});

export const { loadFromStore } = AccountSlice.actions;

export default AccountSlice.reducer;
