import React, { useEffect, useRef, useState } from "react";
import Slider from "@mui/material/Slider";
enum InclinationType {
  LowerHalf,
  UpperHalf,
  Full,
}

export const VenetianBlindSideViewCanvas = () => {
  const [rotation, setRotation] = useState(0);
  const handleRotationChange = (event: Event, value: number | number[]) => {
    setRotation(value as number);
  };

  return (
    <>
      <Slider
        value={rotation}
        onChange={handleRotationChange}
        valueLabelDisplay="auto"
        step={0.001}
        min={0}
        max={1}
        style={{
          width: "70%",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
      <Canvas
        style={{ width: "100%", height: "100%" }}
        rotation={rotation}
        inclinationType={""}
      />
    </>
  );
};
interface CanvasProps
  extends React.DetailedHTMLProps<
    React.CanvasHTMLAttributes<HTMLCanvasElement>,
    HTMLCanvasElement
  > {
  rotation: number;
  inclinationType: string; // Add inclinationType prop
}
const Canvas: React.FC<CanvasProps> = ({
  rotation,
  inclinationType,
  ...props
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [rectPosition, setRectPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const onresize = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const parent = canvas.parentElement;
      if (!parent) return;

      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
    };
    window.addEventListener("resize", onresize);
    onresize();
    return window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const canvasRect = canvas.getBoundingClientRect();
        setRectPosition({ x: 0, y: e.clientY - canvasRect.y });
      }
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousemove", handleMouseMove);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragging]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const venetianBlindSideSlate = (
      ctx: CanvasRenderingContext2D,
      offsetX: number,
      strokeWidth: number,
      strokeColor: string,
    ) => {
      ctx.lineWidth = 1;
      ctx.strokeStyle = "black";
      ctx.moveTo(offsetX + 10, 0);
      ctx.lineTo(offsetX + 10, ctx.canvas.height);
      ctx.stroke();
      ctx.lineWidth = strokeWidth;
      ctx.strokeStyle = strokeColor;
      const numberOfCurves = 10;
      var curveSegmentHeight = ctx.canvas.height / numberOfCurves;
      let angleDeg: number;

      switch (inclinationType) {
        case "Lower 90°":
          angleDeg = 90 - rotation * 90;
          break;
        case "Upper 90°":
          angleDeg = -rotation * 90;
          break;
        case "Full 180°":
          angleDeg = -rotation * 180;
      }
      //spodnjih 90 = 90 - rotation * 90
      //zgornjih 90 = - rotation * 90
      //180 = - rotation * 180

      for (var i = 0; i < numberOfCurves; i++) {
        var startX = offsetX + 10;
        var startY = i * curveSegmentHeight;

        ctx.save();
        ctx.translate(startX, startY);
        ctx.rotate((-angleDeg! * Math.PI) / 180);
        ctx.translate(-startX, -startY);
        ctx.beginPath();
        startY = startY - curveSegmentHeight / 2;
        ctx.moveTo(startX, startY);

        var controlPoint1X = startX - 5;
        var controlPoint1Y = startY + curveSegmentHeight * 0.3;
        var controlPoint2X = startX + 5;
        var controlPoint2Y = startY + curveSegmentHeight * 0.7;
        var endX = startX;
        var endY = startY + curveSegmentHeight;

        ctx.bezierCurveTo(
          controlPoint1X,
          controlPoint1Y,
          controlPoint2X,
          controlPoint2Y,
          endX,
          endY,
        );
        ctx.stroke();
        ctx.restore();
      }
    };

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    //background
    ctx.fillStyle = "rgba(0, 0, 0, 0.2)";
    venetianBlindSideSlate(ctx, 75, 3, "white");
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
  }, [rectPosition, rotation, inclinationType]);

  return (
    <canvas
      {...props}
      style={{ width: "100%", height: "100%" }}
      ref={canvasRef}
    />
  );
};
